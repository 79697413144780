var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [
        _vm._v(
          "Welcome to your travelify.io administration portal, " +
            _vm._s(_vm.activeUserInfo.displayName)
        ),
      ]),
      _c("div", { staticClass: "vx-row match-height mt-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3 mb-base" },
          [
            _c("announcement-carousel", {
              attrs: { announcements: _vm.announcements },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-2/3 mb-base" },
          [_c("frill-embedded-widget")],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row match-height mt-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-base" },
          [_c("travel-news-feed", { attrs: { newsFeed: _vm.newsFeed } })],
          1
        ),
      ]),
      _vm.showAnnouncementsDialog
        ? _c("announcements-dialog", {
            attrs: { announcements: _vm.announcementPopups },
            on: {
              onClose: function ($event) {
                _vm.showAnnouncementsDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }