var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper",
    {
      ref: "announcementSwiper",
      staticClass: "announcement-carousel w-full",
      attrs: { options: _vm.swiperOptions },
    },
    [
      _vm._l(_vm.announcements, function (item, index) {
        return _c(
          "swiper-slide",
          { key: index, staticClass: "carousel-swiper" },
          [
            _c(
              "vx-card",
              { staticClass: "carousel-card", attrs: { title: item.title } },
              [
                _c("div", { domProps: { innerHTML: _vm._s(item.content) } }),
                item.announcmentUrl
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "announcement-link",
                          attrs: {
                            href: item.announcmentUrl,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Details")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      }),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }