var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "travel-newsfeed",
      attrs: {
        title: "Breaking Travel News - Your Source For Travel Industry News",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "tabs-container px-6 pt-6",
          attrs: { slot: "no-body" },
          slot: "no-body",
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col lg:w-2/3 sm:w-full" },
              _vm._l(_vm.newsFeed, function (feed, index) {
                return _c("div", { key: index, staticClass: "mb-4" }, [
                  _c("h5", [
                    _c(
                      "a",
                      {
                        staticClass: "heading-link",
                        attrs: { href: feed.link, target: "_blank" },
                      },
                      [_vm._v(_vm._s(feed.title))]
                    ),
                  ]),
                  _c("p", { staticClass: "mt-1 text-sm" }, [
                    _c("span", [
                      _vm._v(
                        "Posted: " +
                          _vm._s(
                            new Date(feed.publishedDate).toLocaleDateString(
                              "en-gb"
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("p", {
                    staticClass: "mt-1",
                    domProps: { innerHTML: _vm._s(feed.description) },
                  }),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "mt-4" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }