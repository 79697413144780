var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showAnnouncementsDialog,
          expression: "showAnnouncementsDialog",
        },
      ],
      attrs: { width: "650", height: "350", zIndex: "99999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Announcements"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            _vm._l(_vm.announcements, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("h4", {
                    staticClass: "mb-3",
                    domProps: { innerHTML: _vm._s(item.title) },
                  }),
                  _c("div", { domProps: { innerHTML: _vm._s(item.content) } }),
                  item.announcmentUrl
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c(
                          "a",
                          {
                            staticClass: "announcement-link",
                            attrs: {
                              href: item.announcmentUrl,
                              target: "_blank",
                            },
                          },
                          [_vm._v("Details")]
                        ),
                      ])
                    : _vm._e(),
                  _c("vs-divider", { staticClass: "mb-5" }),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Dismiss")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }