var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _c("iframe", {
      ref: "frillWidgetFrame",
      staticClass: "frill-iframe",
      attrs: {
        id: "frillWidget",
        src: _vm.iframe.src,
        width: "100%",
        height: "100%",
        scrolling: "yes",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }